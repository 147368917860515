import classes from "../../style.module.scss";
import { ApplicationState } from "store";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "common/components/loading";
import { requestFailedBulk, requestSucceedsBulk, setBulkPriceList, setIsLoadingData } from "store/slices/bulk-slice";
import { useEffect, useState } from "react";
import { SourceTypes } from "store/types/source-types";
import { Button, Col, Form, ListGroup, Row } from "react-bootstrap";
import { getAllPriceEntitiesByFilter } from "common/adapters/entities";
import { NodeResults } from "common/interfaces/topBottomData";
import DefaultButton from "common/components/button";

// id - DB ID - unique id per source
// eventId - unique identifier


export const BulkNodesTable = () => {
    const dispatch = useDispatch();
    const showCancelButton = true;
    const pageSize = 100;

    const bulkReducer = useSelector((store: ApplicationState) => store.bulkReducer);
    const sourceId = useSelector((store: ApplicationState) => store.settingsReducer.sourceId);
    const sourceName = useSelector((store: ApplicationState) => store.settingsReducer.sourceName);
    const [nodeResults, setNodeResults] = useState<Array<NodeResults>>([]);
    const [selectedNodes, setSelectedNodes] = useState<Array<NodeResults>>([]);
    const [maxResults, setMaxResults] = useState<number>(0);
    const [selectAllAva, setSelectAllAva] = useState<boolean>(false);
    const [selectAllSel, setSelectAllSel] = useState<boolean>(false);
    
    useEffect(() => {
        if (bulkReducer.getPriceListButtonClicked) {
            lookupNodes(0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bulkReducer.getPriceListButtonClicked]);

    async function lookupNodes(offset: number) {
        dispatch(setIsLoadingData(true));
        if (sourceId === SourceTypes.NRG_STREAM) {
            if (bulkReducer.regionId) {
                const response = await getAllPriceEntitiesByFilter(sourceId, bulkReducer.nodeInputValue, false, bulkReducer.regionId, !bulkReducer.nodeActive,
                    bulkReducer.nodeOffset + offset, bulkReducer.locationId, bulkReducer.substationId)
                // console.log('response', response);
                const options: Array<NodeResults> = response.data.items.map((s: any) => {
                    let eventId = sourceId + "_" + s.id;
                    const streams = [];
                    if (s.streams) {
                        for (const stream of s.streams) {
                            streams.push({
                                eventId: sourceId + "_" + stream.id,
                                name: stream.name,
                                streamId: stream.stream_id,
                                minTimestamp: stream.min_timestamp,
                                maxTimestamp: stream.max_timestamp,
                                granularity: stream.granularity,
                                priceType: stream.price_type
                            })
                        }
                    }
                    return {
                        eventId: eventId,
                        id: s.id,
                        name: s.name,
                        checked: false,
                        sourceId: sourceId,
                        sourceName: sourceName,
                        regionId: s.region_id,
                        regionName: s.region_name,
                        locationId: s.location_id,
                        locationName: s.location_name,
                        substationId: s.substation_id,
                        substationName: s.substation_name,
                        streams: streams
                    }
                });
                setMaxResults(response.data.total)
                setNodeResults(options);
                dispatch(requestSucceedsBulk(offset));
            } else {
                dispatch(requestFailedBulk("Region does not exist"));
            } 
        }
        dispatch(setIsLoadingData(false));
    }

    function cancelRequest() {
        setTimeout(() => {
            dispatch(requestFailedBulk(null));
        }, 0);
    }
    function nextResults(forward: boolean) {
        if (forward) {
            lookupNodes(pageSize);
        } else {
            lookupNodes(-pageSize);
        }

    }
    const handleSelectAll = (availableNodes: boolean) => {
        let nodes = nodeResults;
        if (!availableNodes) {
            nodes = selectedNodes;
        }
        let checkedValue = true;
        if (nodes.every(item => item.checked)) {
            checkedValue = false;
        }
        const updatedItems = nodes.map(item => ({ ...item, checked: checkedValue }));
        if (availableNodes) {
            setSelectAllAva(checkedValue);
            setNodeResults(updatedItems);
        } else {
            setSelectAllSel(checkedValue);
            setSelectedNodes(updatedItems);
        }

    };

    const handleCheckboxChange = (id: number, availableNodes: boolean) => {
        let nodes = nodeResults;
        if (!availableNodes) {
            nodes = selectedNodes;
        }
        const updatedItems = nodes.map(item =>
            item.id === id ? { ...item, checked: !item.checked } : item
        );
        if (availableNodes) {
            setNodeResults(updatedItems);
        } else {
            setSelectedNodes(updatedItems);
        }

    };

    const handleMoveSelected = () => {
        const selected = nodeResults.filter(item => item.checked && 
            !selectedNodes.some(selectedItem => selectedItem.eventId === item.eventId)
        );
        // uncheck when moving to selected
        selected.forEach(x => x.checked = false);
        // check if already exists
        setSelectedNodes([...selectedNodes, ...selected]);
        setSelectAllAva(false);
        dispatch(setBulkPriceList([...selectedNodes, ...selected]));

      };

    const removeSelected = () => {
        const remaining = selectedNodes.filter(item => !item.checked);
        setSelectedNodes(remaining);
        setSelectAllSel(false);
        dispatch(setBulkPriceList(remaining));
    };

    const loadingMessage =
        <p>
            <b>Fetching Data...</b>
        </p>

    return (
        
        <Loading isLoading={bulkReducer.isLoadingData} errorMessage={bulkReducer.errorMessage} showButtonCancel={showCancelButton} loadingMessage={loadingMessage} callback={() => cancelRequest()}>
            <div className="container-xxl">
                <Row className="mt-4">
                    <Col>
                        <h3>Available Items</h3>
                        {nodeResults && nodeResults.length > 0 && <ListGroup className="mt-4">
                            <Row className={`d-flex justify-content-between text-nowrap`}>
                                <Col className="d-flex align-items-center mt-2">
                                    <Form.Check
                                        type="checkbox"
                                        checked={selectAllAva}
                                        onChange={() => handleSelectAll(true)}
                                        label="Select All"
                                    />
                                </Col>
                                <Col>
                                    <DefaultButton primary="primary" title="Add" disabled={false} onClick={handleMoveSelected} ></DefaultButton>
                                </Col>
                                <Col>
                                    <Row className="h-100">
                                        <Col>
                                        <Button variant="outline-dark" onClick={() => nextResults(false)} disabled={bulkReducer.nodeOffset <= 0}>Back</Button>
                                        </Col>
                                        <Col>
                                        <Button variant="outline-dark" onClick={() => nextResults(true)} disabled={maxResults <= bulkReducer.nodeOffset + pageSize}>Next</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="my-2">
                                <Col>
                                {nodeResults.map((item) => (
                                    <ListGroup.Item key={item.eventId}>
                                        <Form.Check
                                            type="checkbox"
                                            checked={item.checked}
                                            onChange={() => handleCheckboxChange(item.id, true)}
                                            label={item.name}
                                        />
                                    </ListGroup.Item>
                                ))}
                                </Col>
                            </Row>
                            {nodeResults && nodeResults.length > 0 && 
                            <Row className={`d-flex justify-content-between text-nowrap`}>
                                <Col className="d-flex align-items-center mt-2">
                                    <Form.Check
                                        type="checkbox"
                                        checked={selectAllAva}
                                        onChange={() => handleSelectAll(true)}
                                        label="Select All"
                                    />
                                </Col>
                                <Col>
                                    <DefaultButton primary="primary" title="Add" disabled={false} onClick={handleMoveSelected} ></DefaultButton>
                                </Col>
                                <Col>
                                    <Row className="h-100">
                                        <Col>
                                        <Button variant="outline-dark" onClick={() => nextResults(false)} disabled={bulkReducer.nodeOffset <= 0}>Back</Button>
                                        </Col>
                                        <Col>
                                        <Button variant="outline-dark" onClick={() => nextResults(true)} disabled={maxResults <= bulkReducer.nodeOffset + pageSize}>Next</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            }
                        </ListGroup>
                    }
                    </Col>
                    <Col>
                        <h3>Selected Items</h3>
                        {((nodeResults && nodeResults.length > 0) || (selectedNodes && selectedNodes.length > 0)) && <ListGroup className="mt-4">
                            <Row className={`d-flex justify-content-between text-nowrap`} >
                                <Col className="d-flex align-items-center mt-2">
                                    <Form.Check
                                        type="checkbox"
                                        checked={selectAllSel}
                                        onChange={() => handleSelectAll(false)}
                                        label="Select All"
                                    />
                                </Col>
                                <Col xs={3}>
                                    <DefaultButton primary="warning" title="Remove" disabled={false} onClick={removeSelected} ></DefaultButton>
                                </Col>
                            </Row>
                            <Row className="my-2">
                                <Col>
                                {selectedNodes.map((item) => (
                                    <ListGroup.Item key={item.eventId}>
                                        <Form.Check
                                            type="checkbox"
                                            checked={item.checked}
                                            onChange={() => handleCheckboxChange(item.id, false)}
                                            label={item.name}
                                        />
                                    </ListGroup.Item>
                                ))}
                                </Col>
                            </Row>
                            {selectedNodes && selectedNodes.length > 0 && <Row className={`d-flex justify-content-between text-nowrap`}>
                                <Col className="d-flex align-items-center mt-2">
                                <Form.Check
                                    type="checkbox"
                                    checked={selectAllSel}
                                    onChange={() => handleSelectAll(false)}
                                    label="Select All"
                                />
                                </Col>
                                <Col xs={3}>
                                    <DefaultButton primary="warning" title="Remove" disabled={false} onClick={removeSelected} ></DefaultButton>
                                </Col>
                            </Row>
                            }
                        </ListGroup>
                        }
                    </Col>
                </Row>
            </div>
        </Loading>
        
    )
}